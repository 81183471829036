<template>
  <div id="projectHourHistogramId" style="width: 100%; height: 600px"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'ProjectHourHistogram',
  props: {
    projectHourList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {}
  },
  computed: {},

  watch: {
    projectHourList: {
      deep: true,
      immediate: true,
      handler: function (list) {
        if (list.length > 0) {
          this.init(list)
        } else {
          this.init([])
        }
      },
    },
  },
  async created() {},
  mounted() {},
  methods: {
    // 项目收入统计
    init(arr = []) {
      function truncateToOneDecimal(num) {
        // 将数字转换为字符串，找到小数点的位置
        let str = num.toString()
        let dotIndex = str.indexOf('.')
        // 如果没有小数点，直接返回字符串
        if (dotIndex === -1) {
          return str
        }
        // 截取小数点后一位
        return str.slice(0, dotIndex + 2) // +2 是包括小数点和一位数字
      }
      this.$nextTick(() => {
        var chartDom = document.getElementById('projectHourHistogramId')
        var myChart = echarts.init(chartDom)
        var option
        const nameArr = arr.map(v => v.employeeName)
        const dataArr = arr.map(v => v.totalHours)
        option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
            },
            formatter: function (params) {
              const name = nameArr[params[0].dataIndex] // 获取 x 轴名称
              const value = Number(dataArr[params[0].dataIndex]).toLocaleString() // 获取小时
              return `${name}: ${value} 小时` // 返回 x 轴名称和小时
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              data: nameArr,
              axisTick: {
                alignWithLabel: true,
              },
              axisLabel: {
                interval: 0, // 让所有标签都显示
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
            },
          ],
          series: [
            {
              name: '',
              type: 'bar',
              barWidth: 'auto',
              label: {
                show: true,
                position: 'top',
                formatter: function (params) {
                  const value = dataArr[params.dataIndex]
                  if (value >= 10000) {
                    return truncateToOneDecimal(value / 10000) + 'W'
                  } else if (value >= 1000) {
                    return truncateToOneDecimal(value / 1000) + 'K'
                  } else if (value < 0 && value <= -10000) {
                    return '-' + truncateToOneDecimal(-value / 10000) + 'W'
                  } else if (value < 0 && value <= -1000) {
                    return '-' + truncateToOneDecimal(-value / 1000) + 'K'
                  }
                  if (value == 0) {
                    return ''
                  }
                  return value + '小时'
                },
                fontSize: 14,
                fontWeight: 'bold',
              },
              data: dataArr,
            },
          ],
          dataZoom: {
            type: 'slider', // 滑动条类型
            show: nameArr.length > 15 ? true : false, // 是否显示滑动条,在这里可以根据自己的需求做判断，超过时显示进度条
            startValue: 0, // 展示区域内容的起始数值
            endValue: 14, // 展示区域内容的结束数值
            height: 10, // 滑动条组件高度
            bottom: 5, // 距离图表区域下边的距离
            showDetail: false, // 拖拽时是否显示详情
            showDataShadow: false, // 是否在组件中显示数据阴影
            fillerColor: '#c1c1c1', // 平移条的填充颜色
            borderColor: 'transparent', // 边框颜色
            zoomLock: true, // 锁定视图
            brushSelect: false, // 不可缩放 滑动条默认是有手柄可以进行展示的内容区域缩放的，不太美观
            // 通过该属性可以只滑动，不显示缩放功能
            handleStyle: {
              // 手柄样式
              opacity: 0,
            },
          },
          color: ['#fdcd45'], // 柱状图颜色
        }

        option && myChart.setOption(option)
        myChart.setOption(option, true) // 第二个参数设置为 true，表示完全更新配置项
        //监听窗口变化
        window.addEventListener('resize', function () {
          myChart.resize()
        })
      })
    },
  },
}
</script>
<style scoped></style>
